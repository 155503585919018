import React, {Component} from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField/TextField";
import ArrowLeftIcon from '@material-ui/icons/ArrowBack'
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import {isValidPhone} from "../common/utils/CommonUtils";
import "./join-group.scss"
import {BranchUtils} from "../common/utils/BranchUtils";

class connectProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            success: false,
            error: false,
        }

    }

    sendPhone(phoneNumber) {
        if (!this.state.error) {
            const phone = isValidPhone(phoneNumber);
            if (phone) {
                SegmentAnalytics.track(
                    SegmentEvents.USER_REQUESTED_FOR_APP_FROM_CONNECT_PROVIDER_PAGE,
                    {
                        phone,
                        appRequested: true,
                    });

                let fromPage = 'Connect-Provider';

                BranchUtils.desktopSendSMS(phone, fromPage,'Bottom CTA',fromPage);

                this.setState({
                    success: true,
                })
            } else {
                console.log("this.state.error", this.state.error);
                this.setState({
                    error: true,
                    success: false,
                    phone: "",
                })
            }
        }
    };

    render() {
        return (
            <Layout>
                <div className="join-group-main">
                    <div className="top-card-section">
                        <div className="love-your-home-wrap">
                            <div className="join-group-text-inner">
                                <Button className="back-to-group">
                                    <ArrowLeftIcon/>Back to Provider</Button>
                                <h2 className="all-group-main-heading">Connect
                                    Provider</h2>
                                <p className="all-group-main-para">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Aliquam ut lacus mauris.
                                    Fusce eu purus laoreet, sollicitudin nulla
                                    non, gravida nibh.
                                </p>
                                <div className="input-wrap">
                                    <TextField
                                        id="phone-input"
                                        className="phone-input"
                                        placeholder="+14132300085"
                                        value={this.state.phone}
                                        margin="normal"
                                        onChange={event => {
                                            const {value} = event.target;
                                            this.setState({
                                                phone: value,
                                                error: false,
                                                success: false,
                                            })
                                        }}
                                    />
                                    <button
                                        className="blue-simple-btn"
                                        color="primary"
                                        onClick={() => {
                                            this.sendPhone(this.state.phone)
                                        }}>
                                        Get App
                                    </button>
                                </div>
                                {this.state.error ? <p
                                    className="error-msg">Please Enter Valid
                                    Phone</p> : null}
                                {this.state.success ? <p
                                    className="success-msg">Got it! Sending you a text with the link </p> : null}

                                <div className="store-btns">
                                    <Button className="itune-btn"
                                            color="primary">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://apps.apple.com/us/app/confidant-health/id1478727129">
                                            <img src={require(
                                                '../assets/images/itunes.svg')}
                                                 alt="itune"/>
                                        </a>
                                    </Button>
                                    <Button className="playstore-btn"
                                            color="primary">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en">
                                            <img src={require(
                                                '../assets/images/playstore.png')}
                                                 alt="playstore"/>
                                        </a>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="group-feature-wrapper">
                        <div className="group-feature-list">
                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/help_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>Get Support</h3>
                                    <p>You’re not alone in this process. Meet
                                        and
                                        learn from others who’ve been there
                                        themselves.</p>
                                </div>
                            </div>


                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/doctor_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>Clinical Moderators</h3>
                                    <p>All groups are moderated by trained
                                        healthcare providers, so you can trust
                                        the information
                                        being shared.</p>
                                </div>
                            </div>

                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/dollar_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>Donation Based</h3>
                                    <p>Only pay for the value you receive from
                                        the groups, nothing else. </p>
                                </div>
                            </div>

                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/cloud_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>100% Virtual</h3>
                                    <p>Skip the church basement and meet from
                                        the comfort of your couch at home.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>
        )
    }
}

export default connectProvider
